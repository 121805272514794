.line-svg {
  position: absolute;
  left: 0;
  top: 0;
  overflow: visible !important;
  pointer-events: none !important;
}

.icon-style {
  position: absolute;
  z-index: 10;
  background: var(--color-red);
  border: 4px solid #fff;
  box-shadow: 0 0 10px 0 #516f8d50;
  padding: 5px;
  border-radius: 10px;
  left: 0;
  top: 0;
  animation: icon-pulse infinite 2s;
  cursor: pointer;
}

.icon-style:hover {
  background: #fff;
  border-color:rgba(255, 69, 69, 0.7);
  transition: .5s;
}

.icon-style:hover i svg {
  fill: var(--color-lblue) !important
}

@keyframes icon-pulse {
  0% { box-shadow: 0 0 0px 0 rgba(255, 69, 69, 0.7), 0 0 10px 0 #516f8d50; }
  50% { box-shadow: 0 0 0px 10px rgba(0, 0, 0, 0), 0 0 10px 0 #516f8d50; }
  100% { box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0), 0 0 10px 0 #516f8d50; }
}

.dot-style {
  position: absolute;
  z-index: 10;
  background: #22222280;
  box-shadow: 0 0 20px 5px #516f8d80;
  padding: 3px;
  border-radius: 50px;
  user-select: none;
  pointer-events: none !important;
}


