.col {
  text-align: left;
}

.left-side-text {
  height: 100%;
  align-items: start;
  justify-content: center;
  display: flex;
  flex-flow: column;
}

.left-side-text > * {
  margin-bottom: 50px;
}

h3 {
  font-family: Poppins-Medium;
  color: var(--color-lblue) !important;
  font-size: 28px;
}

h2 {
  font-family: Poppins-Bold;
  color: var(--color-blue) !important;
  line-height: inherit;
  font-size: 40px !important;
}

h4 {
  font-family: Poppins-light;
  color: var(--color-lblue) !important;
  line-height: 1.5;
}
