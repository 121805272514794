.footer-background {
  background: var(--color-blue);
  color: #fff;
}

.footer-background .container {
  padding: 50px 0;
}

.footer-container {
  width: 100%;
  margin: 0;
}

.footer-container .footer-logo {
  display: flex;  
  color: #fff;
  font-size: 30px;
  font-family: Poppins-Medium;
}

.footer-first-col {
  display: flex;
  flex-flow: column;
  gap: 20px;
  font-family: Poppins-Light !important;
  text-align: left;
}

.footer-first-col .footer-address {
  display: flex;
  gap: 10px;
  align-items: center;
}

.footer-first-col .footer-address .address-icon {
  display: flex;
  height: 30px;
  width: 30px;
  background: #fff;
  color: var(--color-blue);
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.footer-first-col .footer-number {
  display: flex;
  gap: 10px;
  /* justify-content: center; */
  align-items: center;
}

.footer-first-col .footer-number .phone-icon {
  display: flex;
  height: 30px;
  width: 30px;
  background: #fff;
  color: var(--color-blue);
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.footer-first-col .footer-socials {
  display: flex;
  flex-flow: row;
  gap: 20px;
  margin-top: 10px;
}

.footer-first-col .footer-socials .footer-social {
  display: flex;
  height: 40px;
  width: 40px;
  background: #fff;
  color: var(--color-blue);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
}

.footer-second-col {
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: left;
}

.footer-second-col .footer-links-title {
  font-size: 22px;
  margin-top: 8px;
  font-family: Poppins-Medium;
}

.footer-second-col ul {
  list-style-type: none;
  padding: 10px 0;
}

.footer-second-col ul li {
  padding: 5px 0;
  /* text-indent: 20px; */
  position: relative;
}

/* .footer-second-col ul li:before {
  content: "•";
  position: absolute;
  left: -18px;
} */

.footer-second-col ul li a {
  text-decoration: none;
  color: #fff;
}

.footer-third-col {
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: left;
}

.footer-third-col .footer-hours-title {
  font-size: 22px;
  margin-top: 8px;
  font-family: Poppins-Medium;
}

.footer-third-col ul {
  list-style-type: none;
  padding: 10px 0;
}

.footer-third-col ul li {
  padding: 5px 0;
  /* text-indent: 20px; */
}

.footer-date {
  padding: 5px 0; 
}

.footer-date-container {
  background: var(--color-lblue);
  width: 100%;
  margin: 0;
  padding: 0 !important;
}

@media (max-width: 767px) {
  .footer-container {
    gap: 30px;
  }
  .footer-first-col {
    align-items: center;
  }
  .footer-second-col {
    align-items: center;
  }
  .footer-third-col {
    align-items: center;
  }
  .footer-links ul, .footer-hours ul, .footer-hours-title {
    margin: 0 !important;
  }
  .footer-third-col ul li {
    text-align: center;
    align-items: center;
  }
  .footer-links{
    text-align: center !important;
    align-items: center !important;
  }
  .footer-address {
    text-align: center;
    display: flex;
    flex-flow: column;
  }
  .footer-address:before {
    content: "Address";
    font-family: Poppins-Bold;
  }
  .address-icon {
    display: none !important;
  }
  .footer-number {
    display:flex;
    flex-flow: column;
  }
  .footer-number:before {
    content: "Phone #";
    font-family: Poppins-Bold;
  }
  .phone-icon {
    display: none !important;
  }
}