.review-card-container {
  display: flex;
  padding: 40px 40px;
  background: #fff;
  margin: 70px 30px 30px 30px;
  border-radius: 20px;
  box-shadow: 0 0 20px 5px #516f8d20;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
  height: 400px;
}

.review-card-container:hover {
  box-shadow: 0 10px 20px 5px #516f8d50;
}

.review-card-container .review-card-header {
  display: flex;
  flex-direction: column;
  align-items: inherit;
  justify-content: inherit;
  border-bottom: 1px solid #eee;
  width: 100%;
  gap: 10px;
  padding-bottom: 10px;
}

.review-card-container .review-card-body {
  display: flex;
  flex-direction: column;
  align-items: inherit;
  justify-content: inherit;
}

.review-card-container .review-card-footer {
  display: flex;
  flex-direction: column;
  align-items: inherit;
  justify-content: inherit;
}

.review-card-container .review-card-header .review-card-logo {
  display: flex;
  background: #eee;
  height: 50px;
  width: 50px;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-size: 24px;
  color: var(--color-lblue);
}

.review-card-container .review-card-header .review-card-name {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Poppins-Medium;
}

.review-card-container .review-card-header .review-card-name {
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 1px;
}

.review-card-container .review-card-body .review-card-statement {
  font-size: 14px;
  text-align: justify;
  padding: 0 15px;
}

.review-card-container .review-card-body .review-card-statement a {
  font-family: Poppins-Medium;
  color: var(--color-blue);
  text-decoration: none;
}

.review-card-container .review-card-footer .review-card-stars {
  color: #ffb238;
}

.review-card-container .review-card-footer .review-card-stars > * {
  margin: 2px;
}