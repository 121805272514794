body {
    background: #efefef;
}

.placeholder_container {
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.placeholder_loader {
    width: 100%;
}