#set-height  {
  display: block
}

.video-block {
  display: block;
  position: relative;
  height: 100%;
}

#v0 {
  position: relative;
  width: 100%;
  height: 100%;
  /* border: 5px solid #fff;
  box-shadow: 0 0 20px 0px #516f8d96; */
}

.text-left-side {
  position: relative;
  margin-left: 30px;
}

.angled-texture:nth-of-type(1) {
  /* content: ""; */
  position: absolute;
  right: -100px;
  top: -100px;
  height: 150%;
  width: 300px;
  background: rgb(255, 255, 255);
  transform: rotate(20deg);
  z-index: 10;
}

.angled-texture:nth-of-type(2) {
  /* content: ""; */
  position: absolute;
  right: -70px;
  top: -100px;
  height: 150%;
  width: 300px;
  background: rgb(255, 255, 255);
  transform: rotate(20deg);
  z-index: 10;
  box-shadow: 20px 0px 10px -10px #516f8d20;
}

.video-container {
  /* overflow: hidden; */
  background: linear-gradient(90deg, #f05454, #287ed4);
  height: 100px;
}

.middle-pc-container {
  position: absolute;
  z-index: 5;
}

.middle-pc {
  position: relative;
  height: 484px;
  width: 381px;
  margin-top: -10rem;
  animation: colorize 30s infinite;
  left: 125px;
}

@keyframes colorize {
  0% {filter: hue-rotate(0deg);}
  /* 50% {} */
  100% {filter: hue-rotate(359deg);}
}

.text {
  display: flex;
  align-items: center;
  height: 100%;
  font-family: Poppins-Medium;
  color: #fff !important;
}

.text.text-left {
  justify-content: right;
  vertical-align: middle;
}

.background-circle {
  display: block;
  height: 500px;
  position: absolute;
  top: -100px;
  left: 80px;
  width: 500px;
  background: white;
  border-radius: 100%;
  z-index: 2;
}