.services-title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  vertical-align: middle;
}

.services-title h2 {
  margin-bottom: 10px;
}

.services-title h5 {
  margin-bottom: 30px;
  line-height: 30px;
}

.services-choices .services-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 5px 5px #516f8d10;
  padding: 10px;
  /* border-radius: 50px; */
  /* width: 70%; */
  /* cursor: pointer; */
  transition: .5s;
  margin: 5px auto;
}

.services-choices .services-block:hover {
  box-shadow: 0px 0 20px 5px #516f8d40;
}

.services-choices .services-block:hover .services-icon-block {
  color: var(--color-red);
}

.services-choices .services-block .services-icon-block {
  display: flex;
  background: #eee;
  height: 50px;
  width: 50px;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  /* border-radius: 50px; */
  transition: 0.5s;
}

.services-choices .services-block .services-title {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 15px;
  padding: 0px 10px;
}

.services-container {
  display: flex;
  width: 100%;
  flex-flow: wrap;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.services-title h4 {
  margin: 20px 0;
}

@media (max-width: 767px) {
  .services-container .services-choices .services-title {
    font-size: 12px !important;
  }
  .services-container .services-choices .services-icon-block {
    width: 30px;
    height: 30px;
  }
  .services-container .services-choices .services-icon-block svg {
    font-size: 16px;
  }
}
