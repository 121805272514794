@font-face {
  font-family: Poppins-Bold;
  src: url('./assets/fonts/poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Poppins-Medium;
  src: url('./assets/fonts/poppins/Poppins-Medium.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Poppins-Light;
  src: url('./assets/fonts/poppins/Poppins-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url('./assets/fonts/poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

.App {
  text-align: center;
  font-family: Poppins-Light;
}

.margin-sep {
  margin-top: 6rem;
  margin-bottom: 6rem;
  display: table;
}

.button-style {
  border: none;
  padding: 5px 20px;
  border-radius: 25px;
  box-shadow: 0 0 35px #30475e20;
  font-family: Poppins-Medium;
  color: #fff;
  margin-right: 10px
}

.button-style-red {
  background-color: var(--color-red);
}

.button-style-blue {
  background-color: var(--color-blue);
}

h2 {
  margin-bottom: 50px;
}

.invert-colors > :is(h2, h4) {
  color: #fff !important;
}