.styled-image-block {
  position: relative;
  display: table;
  border: 5px solid #fff;
  border-radius: 25px;
  overflow: hidden;
  box-shadow: 0 0 35px #30475e50;
  width: 300px;
  height: 350px;
}

.styled-image-block span {
  display: block;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}

.styled-image-block.img1 {
  z-index: 10;
  margin-left: 50px;

}

.styled-image-block.img2 {
  z-index: 8;
  margin-top:-250px;
  margin-left: 300px;
}

.styled-image-block.img3 {
  z-index: 6;
  margin-top:-200px;
  margin-left: 100px;
}


@media (max-width: 767px) {
  .styled-image-block {
    width: 150px;
    height: 175px;
  }

  .styled-image-block.img1 {
    z-index: 10;
    margin-left: 50px;
  
  }
  
  .styled-image-block.img2 {
    z-index: 8;
    margin-top:-150px;
    margin-left: 250px;
  }
  
  .styled-image-block.img3 {
    z-index: 6;
    margin-top:-100px;
    margin-left: 150px;
  }
}

@media (max-width: 414px) {
  .styled-image-block {
    width: 150px;
    height: 175px;
  }

  .styled-image-block.img1 {
    z-index: 10;
    margin-left: 0px;
  
  }
  
  .styled-image-block.img2 {
    z-index: 8;
    margin-top:-150px;
    margin-left: 150px;
  }
  
  .styled-image-block.img3 {
    z-index: 6;
    margin-top:-100px;
    margin-left: 50px;
  }
}