.why-us-container {
  border-radius: 50px;
  box-shadow: 0 0 20px 5px #516f8d20;
  padding: 40px;
}

.why-us-block {
  padding: 20px;
}

.why-us-container .why-us-icon-title-block {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: left;
  margin-bottom: 20px;
}

.why-us-container .why-us-icon-title-block h3 {
  margin-bottom: 0px;
}

.why-us-container .why-us-icon-title-block .why-us-icon-block {
  background-color: #eeeeee80;
  height: 70px;
  width: 70px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.why-us-container .why-us-icon-title-block .why-us-text-title {
  margin-left: 20px;
}

.why-us-container .row > div {
  padding: 20px;
}

.why-us-container .row > div:nth-of-type(1) {
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.why-us-container .row > div:nth-of-type(2) {
  border-bottom: 1px solid #eee;
}

.why-us-container .row > div:nth-of-type(3) {
  border-right: 1px solid #eee;
}

.why-us-block .why-us-text-block {
  text-align: left;
  font-family: Poppins-light;
  color: var(--color-lblue)
}

.row-titles h4 {
  color: var(--color-red) !important;
  font-family: Poppins-Medium;
}

@media (max-width: 767px) {
  .why-us-container {
    box-shadow: none !important;
  }
  .why-us-text-body {
    align-items: center !important;
  }
  .why-us-container .row > div {
    border-right: none !important;
  }
  .why-us-container .row > div .why-us-text-title {
    font-size: 18px;
    /* margin: 5px; */
    text-align: left;
  }
  .why-us-container .row > div:nth-child(3) {
    border-bottom: 1px solid #eee;
  }
  .why-us-container .why-us-icon-block {
    width: 50px !important;
    height: 50px !important;
  }
  .why-us-container .why-us-icon-block svg {
    font-size: 25px !important;
  }
}
