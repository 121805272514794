#reviews-container {
  /* background: linear-gradient(90deg, #f054549e, #287ed49e); */
}

.reviews-title-container h2 {
  margin-bottom: 10px;
}

.reviews-title-container h5 {
  margin-bottom: 30px;
}

#reviews-container .more-reviews {
  background: #fff;
  color: var(--color-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  text-align: center;
  margin: 0 auto 30px auto;
  transition: 0.3s;
  box-shadow: 0 0 20px 5px #516f8d20;
}

#reviews-container .more-reviews:hover {
  background: #fff;
  color: var(--color-blue);
  box-shadow: 0 5px 5px 0px #516f8d80;
}

#reviews-container .more-reviews:hover svg {
  animation: rotate 1s infinite ease;
}

@keyframes rotate {
  0% {transform: rotate(0deg)}
  100% {transform: rotate(360deg)}
}

#reviews-container .review-card-col:nth-of-type(2) .review-card-container {
  margin-top: 50px;
}

@media (max-width: 767px) {


  .review-card-container {
    padding: 20px 10px !important;
    height: 300px !important;
    margin: 10px 30px !important;
  }
}
