.logo {
  width: 60%;
}

.navbar {
  transition: .5s;
}

.navbar-nav {
  justify-content: end;
  flex-grow: 4;
  gap: 1rem;
}

.navbar-nav a {
  margin-right: 10px;
  font-size: 1rem;
  color: #30475E !important;
  font-family: Poppins-SemiBold;
  text-decoration: none !important;
}

.NavigationBar .navbar-brand {
  font-family: Poppins-Bold;
  font-size: 30px;
  transition: .5s;
}

.NavigationBar .navbar-brand span:nth-of-type(1) {
  color: var(--color-blue);
}

.NavigationBar .navbar-brand span:nth-of-type(2) {
  color: var(--color-red);
}

.navbar-collapse.show {
  /* box-shadow: 0px 5px 10px 0px #30475e17; */
  /* padding: 10px; */
}

.shadow-drop {
  box-shadow: 0px 5px 10px 0px #30475e17;
  transition: .5s;
  /* z-index: 20; */
  /* padding-top: 5px; */
  /* padding-bottom: 5px; */
}

.shadow-drop .navbar-brand {
  /* font-size: 25px; */
  transition: .5s;
}

@media (max-width: 375px) {
  .NavigationBar .navbar-brand {
    font-size: 25px !important;
  }
}